const scrollToButtons = document.querySelectorAll("[data-scroll-to]");

scrollToButtons.forEach((btn) => {
  btn.addEventListener("click", (e) => {
    const scrollToId = e.target.getAttribute("data-scroll-to");

    document.getElementById(scrollToId)?.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" });
  });
});

async function translate(lang, prevLang) {
  let dictionary;

  try {
    dictionary = await fetch(`${lang}.json`).then((response) => response.json());
  } catch (e) {
    console.error(`Fail to load "${lang}" dictionary: ${e.message}`);
  }

  const nodes = document.querySelectorAll("[data-i18n]");
  for (const node of nodes) {
    if (prevLang === "en" && !node.dataset.i18nEn) {
      // node.setAttribute("data-i18n-en", node.textContent);
      node.dataset.i18nEn = node.textContent;
    }

    // node.textContent = dictionary?.[node.getAttribute("data-i18n")] ?? node.getAttribute("data-i18n-en");
    node.textContent = dictionary?.[node.dataset.i18n] ?? node.dataset.i18nEn;
  }
}

const langSelect = document.getElementById("lang-select");
langSelect.addEventListener("change", (e) => {
  const prevLang = document.documentElement.lang;
  document.documentElement.lang = e.target.value;

  translate(e.target.value, prevLang);
});
